// Load Styles
// import '../scss/main.scss';

// Load Bootstrap init
// import { initBootstrap } from "./bootstrap.js";

// // Loading bootstrap with optional features
// initBootstrap({
//     tooltip: false,
//     popover: false,
//     toasts: false,
// });

// Your app code
// console.log(`Hello ${process.env.HELLO}`);

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger.js';
import SplitText from 'gsap/SplitText.js';
gsap.registerPlugin(ScrollTrigger, SplitText);

function init() {
    // set animations
    let title1 = document.querySelector(".title .t1"),
        title2 = document.querySelector(".title .t2");
    let split1 = new SplitText(title1, { type: "chars" }),
        split2 = new SplitText(title2, { type: "chars" });
    let titleImg = document.querySelector(".title img");
    let tl1 = gsap.timeline();

    tl1.to(document.body, {autoAlpha: 1, duration: 0.5, delay: 1});

    tl1.from(split1.chars, {
        autoAlpha: 0,
        y: "+=15",
        stagger: 0.05,
        delay: 0.5,
        duration: 0.5,
    })
        .from(titleImg, {
            autoAlpha: 0,
            duration: 0.3,
        })
        .from(split2.chars, {
            autoAlpha: 0,
            y: "+=15",
            stagger: 0.05,
            duration: 0.5,
        });

    let bottomEl = document.querySelector(".bottom-el");
    let bottomElwrapper = document.querySelector(".bottm-el-wrapper");
    let topEl = document.querySelector(".top-el");
    let video = document.querySelector(".video");
    tl1.add(gsap.from(bottomEl, {scale: 0, duration: 1.9, ease: "power4.out"}), "<")
        .add(gsap.from(topEl, {scale: 0, duration: 1.9, ease: "power4.out"}), "<+=0.3")
        .add(gsap.from(video, {scale: 0, duration: 1.9, ease: "power4.out"}), "<+=0.3");

    let texts = document.querySelectorAll(".text-content .texts .text");
    let _h = 0;
    let textPause = 5;//seconds
    texts.forEach(t => {t.offsetHeight > _h ? _h = t.offsetHeight : _h = _h});
    document.querySelector(".text-content .texts").style.height = _h + "px";
    let tl2 = gsap.timeline({repeat: -1});
    let txt1 = new SplitText(document.querySelector(".text-content .text1"), {type: "words,lines"});
    let txt2 = new SplitText(document.querySelector(".text-content .text2"), {type: "words,lines"});
    let txt3 = new SplitText(document.querySelector(".text-content .text3"), {type: "words,lines"});
    let ani1From = gsap.from(txt1.lines, {duration: 0.6, autoAlpha: 0, stagger: 0.2});
    let ani1To = gsap.to(txt1.lines, {duration: 0.3, autoAlpha: 0, stagger: 0.1});
    let ani2From = gsap.from(txt2.lines, {duration: 0.6, autoAlpha: 0, stagger: 0.2});
    let ani2To = gsap.to(txt2.lines, {duration: 0.3, autoAlpha: 0, stagger: 0.1});
    let ani3From = gsap.from(txt3.lines, {duration: 0.6, autoAlpha: 0, stagger: 0.2});
    let ani3To = gsap.to(txt3.lines, {duration: 0.3, autoAlpha: 0, stagger: 0.1});
    let pause = gsap.to({t:0}, {duration: textPause, t:1});
    tl2.add(ani1From)
        .add(gsap.to(document.querySelector(".text-content .text1 span"), {duration: textPause, width: "100%", ease: "none"}), ">")
        .add(pause, "<")
        .add(ani1To)
        .add(ani2From)
        .add(gsap.to(document.querySelector(".text-content .text2 span"), {duration: textPause, width: "100%", ease: "none"}), ">")
        .add(pause, "<")
        .add(ani2To)
        .add(ani3From)
        .add(gsap.to(document.querySelector(".text-content .text3 span"), {duration: textPause, width: "100%", ease: "none"}), ">")
        .add(pause, "<")
        .add(ani3To)

    tl1.add(
        gsap.from(document.querySelector(".text-content .btn"), {duration: 0.3, autoAlpha: 0}), ">0.8"
    )
    tl1.add(tl2, ">-0.8");
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
        tl1.add(gsap.timeline({repeat: -1}).to(bottomElwrapper, {duration: 2, scale: (1 + 0.05*Math.random())}).to(bottomElwrapper, {duration: 2, scale: 1}));
    })

    // document.querySelector(".text-content .texts")
    //     .addEventListener('mouseover', () => tl1.pause());
    // document.querySelector(".text-content .texts")
    //     .addEventListener('mouseleave', () => tl1.resume());
    
}



window.addEventListener("load", init);


// magnetic buttons
let magnets = document.querySelectorAll('.magnetic')
let strength = 50

magnets.forEach( (magnet) => {
    magnet.addEventListener('mousemove', moveMagnet );
    magnet.addEventListener('mouseout', function(event) {
        gsap.to( event.currentTarget, {duration: 1, x: 0, y: 0, ease: "power4.out"})
    } );
});

function moveMagnet(event) {
    var magnetButton = event.currentTarget
    var bounding = magnetButton.getBoundingClientRect()

    //console.log(magnetButton, bounding)

    gsap.to( magnetButton, {
        duration: 1,
        x: ((( event.clientX - bounding.left)/magnetButton.offsetWidth) - 0.5) * strength,
        y: ((( event.clientY - bounding.top)/magnetButton.offsetHeight) - 0.5) * strength,
        ease: "power4.out"
    })

    //magnetButton.style.transform = 'translate(' + (((( event.clientX - bounding.left)/(magnetButton.offsetWidth))) - 0.5) * strength + 'px,'+ (((( event.clientY - bounding.top)/(magnetButton.offsetHeight))) - 0.5) * strength + 'px)';
}
